var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[(!_vm.myRoleSettings)?_c('fc-role-loading',{attrs:{"my-role-settings":_vm.myRoleSettings,"page-title":_vm.pageTitle}}):[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),_c('v-sheet',{staticClass:"ml-1",attrs:{"outlined":"","rounded":"","min-width":"200px"}},[_c('v-text-field',{attrs:{"label":"フリーワードで絞り込み","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}})],1),_c('fc-check-filter',{staticClass:"ml-1",attrs:{"options":_vm.status,"label":"ステータスで絞り込み"},model:{value:(_vm.selectedStatusValues),callback:function ($$v) {_vm.selectedStatusValues=$$v},expression:"selectedStatusValues"}}),_c('fc-check-filter',{staticClass:"ml-1",attrs:{"options":_vm.types,"label":"タイプで絞り込み"},model:{value:(_vm.selectedTypeValues),callback:function ($$v) {_vm.selectedTypeValues=$$v},expression:"selectedTypeValues"}}),(_vm.myRoleSettings.createAndUpdate)?_c('v-btn',{staticClass:"ml-1",attrs:{"elevation":"0","color":"primary","to":"/playlists/create"}},[_vm._v("新規作成")]):_vm._e()],1),_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('v-data-table',{staticClass:"fixed-header",attrs:{"headers":_vm.headers,"items":_vm.displayPlayLists,"items-per-page":-1,"loading":_vm.isLoading,"loader-height":"2","height":_vm.tableHeight,"options":_vm.pagination,"footerProps":{
          'items-per-page-text': '行/ページ:',
          'items-per-page-options': [100, 200, 300],
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.streamStatus",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{class:{
              warning: item.streamStatus === 'before',
              primary: item.streamStatus === 'now',
              error: item.streamStatus === 'finish',
            },attrs:{"small":""}},[(item.streamStatus === 'before')?_c('span',[_vm._v("配信前")]):(item.streamStatus === 'now')?_c('span',[_vm._v("配信中")]):(item.streamStatus === 'finish')?_c('span',[_vm._v("配信終了")]):_vm._e()])]}},{key:"item.thumbnail",fn:function(ref){
            var item = ref.item;
return [_c('fc-static-image',{staticClass:"static-image",attrs:{"image":item.thumbnail}})]}},{key:"item.playlistType",fn:function(ref){
            var item = ref.item;
return [(item.playlistType === 'album')?_c('span',[_vm._v("アルバム")]):(item.playlistType === 'single')?_c('span',[_vm._v("シングル")]):(item.playlistType === 'playlist')?_c('span',[_vm._v("プレイリスト")]):_vm._e()]}},{key:"item.playlistName",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.playlistName))])]}},{key:"item.releaseDate.value",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.releaseDate.date))])]}},{key:"item.distributionDate",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.distributionStartDate.dateTime)+" 〜 "+_vm._s(item.distributionEndDate.dateTime))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.myRoleSettings.createAndUpdate)?_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","to":{
                  path: ("/playlists/" + (item.playlistId)),
                  params: {
                    playlistId: item.playlistId,
                  },
                },"loading":_vm.isSaving}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("編集")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.myRoleSettings.createAndUpdate)?_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","loading":_vm.isSaving},on:{"click":function($event){return _vm.deletePlaylist(item.playlistId)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("削除")])])]}}],null,true)})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }