

























































































































import { DisplayDate } from '@/admin/util';
import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { usePlayList } from '@/composition/music';
import { useConfirm } from '@/composition/confirm';
import { useNotification } from '@/composition/notification';
import FcStaticImage from '@/components/FcStaticImage.vue';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import myAttributes from '@/composition/myAttributes';
import FcCheckFilter from '@/components/FcCheckFilter.vue';

export default defineComponent({
  name: 'PlayLists',
  components: {
    FcStaticImage,
    FcRoleLoading,
    FcCheckFilter,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('musics'));
    const { confirmDialog } = useConfirm();
    const notification = useNotification();
    const playlist = usePlayList();

    const isLoading = ref(false); // ロード中フラグ
    const isSaving = ref(false); // DB更新中フラグ
    const searchWord = ref(''); // 検索ワード
    const selectedStatusValues: Ref<string[]> = ref([]); // 選択されているステータスタイプ
    const selectedTypeValues: Ref<string[]> = ref([]); // 選択されているプレイリストタイプ
    // 画面表示用プレイリスト一覧
    const displayPlayLists = computed(() => {
      return (
        playlist.playlists
          ?.filter(
            (playlist) =>
              // ステータスで絞り込み
              !selectedStatusValues.value.length ||
              (playlist.streamStatus && selectedStatusValues.value.includes(playlist.streamStatus))
          )
          .filter(
            (playlist) =>
              // プレイリストタイプで絞り込み
              !selectedTypeValues.value.length ||
              (playlist.playlistType && selectedTypeValues.value.includes(playlist.playlistType))
          )
          // 検索キーワードで絞り込み
          .filter((playlist) => !searchWord.value || JSON.stringify(playlist).includes(searchWord.value))
      );
    });
    // 一覧に表示する項目
    const headers = [
      { text: 'ステータス', value: 'streamStatus' },
      { text: 'サムネイル', value: 'thumbnail' },
      { text: 'プレイリストタイプ', value: 'playlistType' },
      { text: 'プレイリスト名', value: 'playlistName' },
      { text: '発売日', value: 'releaseDate.value' }, // ソートするためにvalueはnumberにしておく
      { text: '配信日時', value: 'distributionDate', sortable: false },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];
    // ステータスのタイプ
    const status = [
      { text: '配信前', value: 'before' },
      { text: '配信中', value: 'now' },
      { text: '配信終了', value: 'finish' },
    ];
    // プレイリストのタイプ
    const types = [
      { text: 'アルバム', value: 'album' },
      { text: 'シングル', value: 'single' },
      { text: 'プレイリスト', value: 'playlist' },
    ];
    // ページネーションデフォルト設定
    const pagination = {
      page: 1,
      itemsPerPage: 100,
    };
    const tableHeight = window.innerHeight - 163; // テーブル枠の高さ

    /**
     * プレイリスト削除処理
     *
     * @param playlistId - プレイリストId
     */
    const deletePlaylist = async (playlistId: string) => {
      if (!(await confirmDialog('本当に削除しますか？\n削除したら元に戻せません'))) return;
      isSaving.value = true;
      try {
        await playlist.deletePlaylist(playlistId);
        notification.notify('削除しました');
        await playlist.getPlayLists();
      } catch (error) {
        notification.error(error);
      } finally {
        isSaving.value = false;
      }
    };

    /**
     * 初期表示処理
     */
    const init = async () => {
      isLoading.value = true;
      try {
        await playlist.getPlayLists();
      } catch (error) {
        notification.error(error);
      } finally {
        isLoading.value = false;
      }
    };

    init();

    return {
      pageTitle: 'プレイリスト一覧',
      myRoleSettings,
      playlist,
      isLoading,
      isSaving,
      searchWord,
      selectedStatusValues,
      selectedTypeValues,
      displayPlayLists,
      headers,
      status,
      types,
      pagination,
      tableHeight,
      deletePlaylist,
      DisplayDate,
    };
  },
});
